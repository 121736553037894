import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/organisms/seo"
import Masthead from "../components/organisms/masthead"
import Footer from "../components/organisms/footer"

// Images
import ImgMastheadHome from "../components/images/_masthead/img-masthead-home"
import Banner404 from "../components/molecules/banner-404";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Page" />
    
    <Masthead 
      image={<ImgMastheadHome/>}
      large={false}
    >
      <Banner404/>
    </Masthead>

    <Footer/>

  </Layout>
)

export default NotFoundPage
